<template>
  <div class="characters-list fixed-draggable-dynamic-table-wrapper-height">
    <draggable-dynamic-table ref="charactersListTable"
                             :options="options"
                             :columns="columnsLabel"
                             :data="data"
                             :zero-top-radius="true"
                             @row:clicked="showCharacter"
                             @load:more="getCharactersTrash"
                             @user:restore="restoreCharacter($event)"
                             @user:deleted="$refs.deleteCharacterConfirmation.showDialog(), characterDataForDelete = $event"/>


    <custom-dialog ref="deleteCharacterConfirmation"
                   :title="$t('characters.confirmations.forceDelete.title')"
                   :body="$t('characters.confirmations.forceDelete.body', {name: characterDataForDelete.name})"
                   @accept="deleteCharacter(characterDataForDelete)"
                   @close=""/>

    <button id="insertNewCharacter" v-show="false" @click="showInsertCharacterPromptStatus = true"/>
  </div>
</template>

<script>
  import axios from 'axios'
  import {deleteCharacter, getCharactersTrash, restoreCharacter} from "../../../../http/requests/characters"
  import CustomIcon from "../../../../components/customIcon/customIcon";
  import InsertCharacter from "../insert/insertCharacter";
  import CharacterUserList from "../users/list/characterUserList";
  import CharacterProfile from "../profile/characterProfile";
  import EditCharacter from "../edit/editCharacter";
  import {checkUserPermissions} from "../../../../assets/js/functions";
  import CustomDialog from "../../../../components/customDialog/customDialog";

  export default {
    name: "charactersList",
    components: {CustomDialog, EditCharacter, CharacterProfile, CharacterUserList, InsertCharacter, CustomIcon},
    data() {
      return {
        showInsertCharacterPromptStatus: false,
        showCharacterUsersListPromptStatus: false,
        showCharacterProfilePromptStatus: false,
        showEditCharacterPromptStatus: false,
        selectedCharacter: {},
        requestSent: false,
        loadingTimer: 0,
        characterDataForDelete: {},
        options: {
          id: 'charactersListTable',
          rowKeyField: 'id'
        },
        columnsLabel: [
          {
            field: 'storeAction',
            i18n: 'characters.table.header.restore',
            color: 'success',
            width: '70px',
            minWidth: 70,
            locked: true,
            actions: true,
            showAction: 'always',
            event: 'user:restore',
            action: {
              icon: 'icon-refresh-cw',
              iconPack: 'feather',
              color: 'success',
              type: 'button'
            },
            classes: 'mx-auto'
          },
          {
            field: 'deleteAction',
            i18n: 'characters.table.header.delete',
            color: 'danger',
            width: '70px',
            minWidth: 70,
            align: 'center',
            locked: true,
            actions: true,
            showAction: 'always',
            event: 'user:deleted',
            action: {
              icon: 'icon-trash',
              iconPack: 'feather',
              color: 'danger',
              type: 'button'
            },
            classes: 'mx-auto'
          },
          {
            field: 'createdDate',
            i18n: 'characters.table.header.createdDate',
            width: 'calc(100% / 3)',
            minWidth: 120,
            align: 'center',
          },
          {
            field: 'name',
            i18n: 'characters.table.header.name',
            width: 'calc((100% / 3) * 2)',
            minWidth: 150,
          },
          {
            field: 'row',
            i18n: 'characters.table.header.row',
            width: '50px',
            minWidth: 50,
            align: 'center',
            // locked: true,
            // sortable: true,
            footer: {
              /*type: 'auto-counter'*/
            }
          }
        ],
        data: [],
        filters: [],
        sorts: ['order[0]=created_at,desc'],
        page: 1,
        endedList: false,
        actions: {
          leftToolbar: [
            {
              id: 'printTable',
              // i18n: 'draggableDynamicTable.actions.print',
              icon: 'icon-printer',
              iconPack: 'feather'
            },
            {
              id: 'downloadTable',
              // i18n: 'draggableDynamicTable.actions.download',
              icon: 'icon-download',
              iconPack: 'feather'
            },
            {
              id: {name: 'characters'},
              type: 'link',
              icon: 'icon-chevron-left',
              iconPack: 'feather'
            }
          ]
        },
      }
    },
    created() {
      if (!checkUserPermissions('character.forceDelete')) {
        for (let i = 0; i < this.columnsLabel.length; i++) {
          if (this.columnsLabel[i].field === 'deleteAction') {
            this.columnsLabel.splice(i, 1)
          }
        }
      }

      if (!checkUserPermissions('character.restore')) {
        for (let i = 0; i < this.columnsLabel.length; i++) {
          if (this.columnsLabel[i].field === 'storeAction') {
            this.columnsLabel.splice(i, 1)
          }
        }
      }

      setTimeout(() => {
        this.$store.dispatch('updateNavbarActions', this.actions)
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
        this.$store.dispatch('updateContentNavbarClass', '')
      }, 50)
      this.getCharactersTrash()
    },
    methods: {
      getCharactersTrash() {
        if (!this.requestSent) {
          this.requestSent = true

          clearTimeout(this.loadingTimer)
          this.loadingTimer = setTimeout(() => {
            if (!this.endedList) {
              if (this.$refs.charactersListTable && this.data.length === 0) {
                this.$refs.charactersListTable.loadMoreStatus = 'FirstLoad'
              } else if (this.$refs.charactersListTable && this.data.length > 0) {
                this.$refs.charactersListTable.loadMoreStatus = 'Loading'
              }

              getCharactersTrash(this.page, this.filters, this.sorts).then((res) => {
                this.endedList = res.data.data.length === 0
                const characters = res.data.data
                characters.forEach((character) => {
                  this.data.push({
                    row: this.data.length + 1,
                    id: character.id,
                    name: character.name || '',
                    createdDate: character.created_at || ''
                  })
                })

                this.page = res.data.pagination.current_page + 1
                if (res.data.pagination.current_page === 1) {
                  const row_index = this.columnsLabel.map((e) => {
                    return e.field
                  }).indexOf('row')
                  this.columnsLabel[row_index].footer.value = res.data.pagination.total
                }

                if (this.$refs.charactersListTable) this.$refs.charactersListTable.loadMoreStatus = ''

                this.requestSent = false
              })
                .catch(() => {
                  if (this.$refs.charactersListTable) this.$refs.charactersListTable.loadMoreStatus = 'Danger'

                  this.requestSent = false
                })
            } else {

              this.requestSent = false
            }
          }, 400)
        }
      },
      showCharacter(row) {
        this.selectedCharacter = row

        if (this.$route.name === 'characters') {
          this.showCharacterProfilePromptStatus = true
        } else {
          this.showCharacterUsersListPromptStatus = true
        }
      },
      handleReloadTable() {
        this.data = []
        this.showInsertCharacterPromptStatus = false
        this.showEditCharacterPromptStatus = false
        this.showCharacterProfilePromptStatus = false
        this.endedList = false
        this.page = 1
        setTimeout(() => {
          this.getCharactersTrash()
        }, 100)
      },
      restoreCharacter(character) {
        restoreCharacter(character.id).then(res => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('characters.notifications.restore.success'),
            color: 'success',
            time: 2400,
            icon: 'icon-check-circle',
            iconPack: 'feather'
          })

          this.data = []
          this.getCharactersTrash()
        })
          .catch(error => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            } else {
              this.$vs.notify({
                title: this.$t('alert.error.title'),
                text: this.$t('characters.notifications.restore.error'),
                color: 'danger',
                time: 2400,
                icon: 'icon-alert-circle',
                iconPack: 'feather'
              })
            }
          })
      },
      deleteCharacter(character) {
        deleteCharacter(character.id, true).then(res => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('characters.notifications.delete.success'),
            color: 'success',
            time: 2400,
            icon: 'icon-check-circle',
            iconPack: 'feather'
          })

          this.data = []
          this.getCharactersTrash()
        })
          .catch(error => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            } else {
              switch (error.response.status) {
                case 409:
                  this.$vs.notify({
                    title: this.$t('alert.error.title'),
                    text: this.$t('characters.notifications.delete.isUsedError'),
                    color: 'danger',
                    icon: 'icon-alert-circle',
                    iconPack: 'feather',
                    time: 5000
                  })
                  break

                default:
                  this.$vs.notify({
                    title: this.$t('alert.error.title'),
                    text: this.$t('characters.notifications.delete.error'),
                    color: 'danger',
                    time: 2400,
                    icon: 'icon-alert-circle',
                    iconPack: 'feather'
                  })
                  break
              }
            }
          })
      },

      handleClick(id) {
        document.getElementById(id).click()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .characters-list {
    &.in-modal {
      height: 100%;
    }
  }
</style>
